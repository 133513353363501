import moment from 'moment'
import { ComponentProps } from 'react'

interface IFormattedDate {
  date?: string | null | Date
  format?: string
}

export const FormattedDate: React.FC<
  IFormattedDate & ComponentProps<'span'>
> = ({ date, format, ...props }) => {
  return (
    <span {...props}>
      {moment(date).format(format ?? 'dddd, MMMM Do YYYY')}
    </span>
  )
}
